@font-face {
  font-family: Manrope Regular;
  font-style: normal;
  font-weight: normal;
  src: local(Manrope Regular), url("manrope-regular.fdb95fde.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Manrope Thin;
  font-style: normal;
  font-weight: normal;
  src: local(Manrope Thin), url("manrope-thin.831f529b.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Manrope Light;
  font-style: normal;
  font-weight: normal;
  src: local(Manrope Light), url("manrope-light.0fa56727.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Manrope Medium;
  font-style: normal;
  font-weight: normal;
  src: local(Manrope Medium), url("manrope-medium.ba4e653d.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Manrope Semibold;
  font-style: normal;
  font-weight: normal;
  src: local(Manrope Semibold), url("manrope-semibold.697deccd.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Manrope Bold;
  font-style: normal;
  font-weight: normal;
  src: local(Manrope Bold), url("manrope-bold.0f343b6e.woff") format("woff");
  font-display: swap;
}

.wrapper {
  color: #fff;
  text-align: center;
  text-rendering: optimizelegibility;
  z-index: 2;
  background-color: #000;
  background-position: center;
  background-size: cover;
  position: fixed;
  inset: 0;
}

.wrapper:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  margin-right: -.25em;
  display: inline-block;
}

.wrapper:after {
  content: "";
  border: 1px solid #fff3;
  position: fixed;
  inset: 10px;
}

.wrapper-inner {
  vertical-align: middle;
  z-index: 2;
  max-width: 80%;
  margin-top: -4%;
  display: inline-block;
  position: relative;
}

.wrapper-inner:before {
  content: "";
  background-image: url("circle-logo.213cfd5a.png");
  background-size: 100%;
  width: 337px;
  height: 337px;
  margin-top: -168px;
  margin-left: -168px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.triangle-pattern {
  opacity: .06;
  background-image: url("triangles.5e651269.png");
  background-position: center;
  background-size: cover;
  position: fixed;
  inset: 0;
}

.name {
  z-index: 2;
  letter-spacing: .26em;
  margin-top: 100px;
  margin-bottom: 500px;
  font-family: manrope;
  font-size: 50px;
  font-weight: 300;
  line-height: 1;
  position: relative;
}

.social-links {
  width: 475px;
  margin-left: -237px;
  padding: 0;
  list-style-type: none;
  position: fixed;
  bottom: 50px;
  left: 50%;
}

.social-links > li {
  display: inline-block;
}

.social-link-item {
  text-indent: -9999px;
  opacity: .4;
  background-image: url("icons-sprite.ae3751fd.png");
  background-size: 123px 20px;
  height: 20px;
  margin-left: 15px;
  -webkit-transition: all .1s ease-in;
  display: inline-block;
}

.social-links .email-link-outer {
  margin-top: 10px;
  display: block;
}

.email-link {
  color: #fff;
  opacity: .5;
  font-family: manrope;
  font-size: 15px;
  text-decoration: none;
  -webkit-transition: opacity .1s ease-in;
}

.email-link:hover, .email-link:focus, .social-link-item:hover {
  opacity: 1;
}

.social-link-item-twitter {
  width: 21px;
  margin-left: 0;
}

.social-link-item-linkedin {
  background-position: -21px 0;
  width: 19px;
}

.social-link-item-github {
  background-position: -40px 0;
  width: 18px;
}

.social-link-item-dribbble {
  background-position: -58px 0;
  width: 21px;
}

.social-link-item-behance {
  background-position: -78px 0;
  width: 26px;
}

.social-link-item-medium {
  background-position: -105px 0;
  width: 18px;
}

@media (width <= 600px) {
  .name {
    letter-spacing: .2em;
    font-size: 30px;
  }
}

@media (width <= 400px) {
  .name {
    letter-spacing: .1em;
    margin-top: 20px;
    font-size: 24px;
  }

  .byline {
    letter-spacing: .2em;
    font-size: 12px;
  }

  .wrapper-inner {
    margin-top: -20%;
  }

  .wrapper-inner:before {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
  }
}
/*# sourceMappingURL=index.31ec50cf.css.map */
