/* Fonts */

@import url("../fonts/manrope/style.css");

/* Structure & Background
----------------------------- */
.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    text-rendering: optimizeLegibility;
    z-index: 2;
  }
  .wrapper:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  .wrapper:after {
    content: '';
    position: fixed;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border: 1px solid rgba(255,255,255,.2);
  }
  .wrapper-inner {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    z-index: 2;
    margin-top: -4%;
    max-width: 80%;
  }
  .wrapper-inner:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 337px;
    height: 337px;
    left: 50%;
    top: 50%;
    margin-left: -168px;
    margin-top: -168px;
    background-image: url(/src/images/circle-logo.png);
    background-size: 100%;
  }
  .triangle-pattern {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/src/images/triangles.png');
    background-size: cover;
    background-position: center;
    opacity: .06;
  }
  
  /* Type
  ----------------------------- */
  .name {
    position: relative;
    z-index: 2;
    font-size: 50px;
    line-height: 1;
    letter-spacing: 0.26em;
    font-weight: 300;
    font-family: "manrope";
    margin-bottom: 500px;
    margin-top: 100px;
  }
  
  /* Social Links
  ----------------------------- */
  .social-links {
    position: fixed;
    width: 475px;
    left: 50%;
    bottom: 50px;
    margin-left: -237px;
    padding: 0;
    list-style-type: none;
  }
  .social-links > li {
    display: inline-block;
  }
  .social-link-item {
    display: inline-block;
    background-image: url(/src/images/icons-sprite.png);
    height: 20px;
    background-size: 123px 20px;
    text-indent: -9999px;
    margin-left: 15px;
    opacity: .4;
    -webkit-transition: all .1s ease-in;
  }
  .social-links .email-link-outer {
    margin-top: 10px;
    display: block;
  } 
  .email-link {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    font-family: "manrope";
    opacity: .5;
    -webkit-transition: opacity .1s ease-in;
  }
  .email-link:hover,
  .email-link:focus {
    opacity: 1;
  } 
  .social-link-item:hover{
    opacity: 1;
  }
  .social-link-item-twitter {
    margin-left: 0;
    width: 21px;
  }
  .social-link-item-linkedin {
    background-position: -21px 0;
    width: 19px;
  }
  .social-link-item-github {
    background-position: -40px 0;
    width: 18px;
  }
  .social-link-item-dribbble {
    background-position: -58px 0;
    width: 21px;
  }
  .social-link-item-behance {
    background-position: -78px 0;
    width: 26px;
  }
  .social-link-item-medium {
    background-position: -105px 0;
    width: 18px;
  }
  
  @media (max-width: 600px) {
    .name {
      font-size: 30px;
      letter-spacing: 0.2em;
    }
  }
  @media (max-width: 400px) {
    .name {
      margin-top: 20px;
      font-size: 24px;
      letter-spacing: 0.1em;
    }
    .byline {
      font-size: 12px;
      letter-spacing: 0.2em;
    }
    .wrapper-inner {
      margin-top: -20%;
    }
    .wrapper-inner:before {
      width: 200px;
      height: 200px;
      margin-left: -100px;
      margin-top: -100px;
    }
  }