/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Manrope Regular';
    font-style: normal;  
    font-weight: normal;
    src: local('Manrope Regular'), url('manrope-regular.woff') format('woff');
    font-display: swap;
    }
    

    @font-face {
    font-family: 'Manrope Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Thin'), url('manrope-thin.woff') format('woff');
    font-display: swap;
    }
    

    @font-face {
    font-family: 'Manrope Light';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Light'), url('manrope-light.woff') format('woff');
    font-display: swap;
    }
    

    @font-face {
    font-family: 'Manrope Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Medium'), url('manrope-medium.woff') format('woff');
    font-display: swap;
    }
    

    @font-face {
    font-family: 'Manrope Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Semibold'), url('manrope-semibold.woff') format('woff');
    font-display: swap;
    }
    

    @font-face {
    font-family: 'Manrope Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Bold'), url('manrope-bold.woff') format('woff');
    font-display: swap;
    }